import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import ProjectData from '../ProjectData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const WebApps = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const typeParam = searchParams.get("type");

  // Define valid project types
  const validTypes = ["AI", "Web Application", "Website", "Data Analysis"];

  // Filter ProjectData based on the type parameter
  const filteredProjects = typeParam ? ProjectData.filter(project => project.type === typeParam) : ProjectData;

  // Check if the type parameter is valid
  const isValidType = validTypes.includes(typeParam);

  // Check if there are no web apps
  const noWebApps = filteredProjects.length <= 0;

  return (
    <div className='my-5 mx-auto'> 
      <div className="container p-5">
        {isValidType ? (
          <>
            {noWebApps ? (
              <div className="alert alert-warning" role="alert">
                Web Application Projects are not updated yet.
              </div>
            ) : (
              <div className="row">
                {filteredProjects.map(project => (
                  <ProjectCard key={project.id} project={project} searchParams={searchParams} />
                ))}
              </div>
            )}
          </>
        ) : (
          <div className="alert alert-danger" role="alert">
            Invalid filter in section.
          </div>
        )}
      </div>
    </div>
  );
};

export default WebApps;

function ProjectCard({ project, searchParams }) {
  const { id, title, type, description, imageUrl } = project;

  return (
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card h-100">
        <img className="card-img-top" src={imageUrl} alt={title} />
        <div className="card-body">
          <h5 className="card-title fs-6 fw-bold">{title}</h5>
          <p className="card-text">{description}</p>
        </div>
        <div className="card-footer d-flex justify-content-between">
          <small className="text-muted">{type}</small>
          <Link 
            to={`/projects/${id}`} 
            state={{ 
              search: `?${searchParams.toString()}`,
              type: type // Pass the type here
            }} 
            className="btn btn-sm border-0 btn-outline-secondary"
          >
            View Details 
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
      </div>
    </div>
  );
}


