import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import ProjectData from '../ProjectData';
import ProjectCard from '../ProjectCard';


const AI = () => {
  const [searchParams] = useSearchParams();
  const typeParam = searchParams.get("type"); // Get the type parameter

  // Define valid project types
  const validTypes = ["AI", "Web Application", "Website", "Data Analysis"];

  // Filter ProjectData based on the type parameter
  const filteredProjects = typeParam ? ProjectData.filter(project => project.type === typeParam) : ProjectData;

  // Check if the type parameter is valid
  const isValidType = validTypes.includes(typeParam);

  // Check if there are no AI projects
  const noAIProjects = filteredProjects.length <= 0;

  return (
    <div className='my-5 mx-auto'> 
      <div className="container p-5">
        {isValidType ? (
          <>
            {noAIProjects ? (
              <div className="alert alert-warning" role="alert">
                AI projects are not updated yet.
              </div>
            ) : (
              <div className="row">
                {filteredProjects.map(project => (
                  <ProjectCard key={project.id} project={project} searchParams={searchParams} typeParam={typeParam} />
                ))}
              </div>
            )}
          </>
        ) : (
          <div className="alert alert-danger" role="alert">
            Invalid filter in section.
          </div>
        )}
      </div>
    </div>
  );
};

export default AI;


// function ProjectCard({ project, searchParams, typeParam }) {
//   const { id, title, type, description, imageUrl } = project;

//   return (
//     <div className="col-lg-4 col-md-6 mb-4">
//       <div className="card h-100">
//         <img className="card-img-top" src={imageUrl} alt={title} />
//         <div className="card-body">
//           <h5 className="card-title fs-6 fw-bold">{title}</h5>
//           <p className="card-text">{description}</p>
//         </div>
//         <div className="card-footer d-flex justify-content-between">
//           <small className="text-muted">{type}</small>
//           <Link 
//             to={`/projects/${id}`} 
//             state={{ 
//               search: `?${searchParams.toString()}`,
//               type: typeParam // Pass the typeParam here
//             }} 
//             className="btn btn-sm border-0 btn-outline-secondary"
//           >
//             View Details 
//             <FontAwesomeIcon icon={faArrowRight} />
//           </Link>
//         </div>
//       </div>
//     </div>
//   );
// }




