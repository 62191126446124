import React from 'react';

const customStyle = {
  color: "#B0E2FF"
}

const Experience = () => {
  return (
    <div className='col-12 col-md-8 mt-3' style={customStyle}>
      <div className='d-flex flex-wrap mx-4'>
        <div className='col-12'>
          <h1 className='fs-5'> Jan 2024 - Current </h1>
          <p className='mb-2'>Contract at Kenya Revenue Authority</p>
        </div>
        <div className='col-12'>
          <h1 className='fs-5'> 2023 </h1>
          <p className='mb-2'>Internship at Kenya Revenue Authority</p>
        </div>
        <div className='col-12'>
          <h1 className='fs-5'> Sept 2022 - Nov 2022 </h1>
          <p className='mb-2'>Industrial Attachment at Kenya Revenue Authority</p>
        </div>
      </div>
    </div>
  );
};

export default Experience;

