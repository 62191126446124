import React, { useEffect, useState } from 'react';
import ProjectData from './ProjectData';
import ProjectCard from './ProjectCard';
import { useSearchParams, useLocation, useLoaderData } from 'react-router-dom';

export function loader() {
  return ProjectData;
}

export default function Projects() {
  const [projects, setProjects] = useState([]);
  const data = useLoaderData();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const typeParam = searchParams.get("type");

  useEffect(() => {
    const filteredProjects = typeParam ? data.filter(project => project.type === typeParam) : data;
    setProjects(filteredProjects);
  }, [typeParam, data]);

  // Check if there are no projects
  const noProjects = projects.length <= 0; // Fix here

  return (
    <div className='my-3 mx-auto'>
      <div 
        className="container p-5 d-flex justify-content-center align-items-center"
        style={{ minHeight: '100vh' }}
      >
        <div className="row py-5">
          {noProjects ? (
            <h6 className="alert alert-warning" role="alert">No projects found or Projects are not yet updated yet.</h6>
          ) : (
            projects.map((project, index) => (
              <ProjectCard 
                key={index}
                project={project}
                index={index}
                searchParams={searchParams} 
                typeParam={typeParam} 
                location={location}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}














// function ProjectCard({ project, index, searchParams, typeParam, location }) {
//   const { id, title, type, description, imageUrl } = project;

//   const cardVariants = {
//     hidden: { scale: 0.8 },
//     show: { scale: 1, transition: { ease: "easeOut", duration: 0.9 + index * 0.3 } }
//   };

//   return (
//     <motion.div className="col-lg-4 col-md-6 mb-4" variants={cardVariants} initial="hidden" whileInView={"show"} viewport={{ once: false, amount: 0.9 }}>
//       <div className="card h-100">
//         <img className="card-img-top" src={imageUrl} alt={title} />
//         <div className="card-body">
//           <h5 className="card-title fs-6 fw-bold">{title}</h5>
//           <p className="card-text">{description}</p>
//         </div>
//         <div className="card-footer d-flex justify-content-between">
//           <small className="text-muted">{type}</small>
//           <Link
//             to={`/projects/${id}`}
//             state={{
//               search: `?${searchParams.toString()}`,
//               type: typeParam // Pass the typeParam here
//             }}
//             className="btn btn-sm border-0 btn-outline-secondary"
//           >
//             View Details
//             <FontAwesomeIcon icon={faArrowRight} />
//           </Link>
//         </div>
//       </div>
//     </motion.div>
//   );
// }
