import React from 'react';
import { useRouteError } from 'react-router-dom';

export default function ErrorElement() {

  const error = useRouteError()

  return (
    <div 
      className="container-fluid px-0 p-5 d-flex justify-content-center align-items-center bgc-about-custom" 
      style={{ height: "calc(100vh - 10rem)", overflow: "hidden" }}
    >
      <div className="container p-5 m-5 alert alert-danger" role="alert">
   
          <h1>Error: {error.message}</h1>
          <pre>{error.status} - {error.statusText}</pre>
      
      </div>
    </div>
  );
}