import { ProjectImages } from "./ProjectImages";


const ProjectData = [

//   {
//     id: 6,
//     title: "My Portfolio Landing Page",
//     type: "Website",
//     description: "Laptop view of My Portfolio Landing Page",
//     imageUrl: ProjectImages?.MyPortfolioLandingPage,
//     languages: {
//       Javascript: 80,
//       HTML: 12,
//       CSS: 8
//     },
//     info: "Description info for My Portfolio Landing Page",
//     sourceCode: "Link to source code for My Portfolio Landing Page",
//     pricing: 680,
//     photos: {
//       // Photos information for My Portfolio Landing Page
//     }
//   },

//   {
//     id: 5,
//     title: "PVWG Financial Management System",
//     type: "Web Application",
//     description: "Laptop view of PVWG advance Loan review.",
//     imageUrl: ProjectImages?.PVWGadvanceLoanreviewRejection,
//     languages: {
//       Python: 60,
//       Javascript: 30,
//       HTML: 2,
//       CSS: 3
//     },
//     info: "Description info for PVWG Financial Management System",
//     sourceCode: "Link to source code for PVWG Financial Management System",
//     pricing: 1145,
//     photos: {
//       // Photos information for PVWG Financial Management System
//     }
//   },

//   {
//     id: 4,
//     title: "BudgetBite AI",
//     type: "AI",
//     description: "Laptop view of BudgetBite AI Login Page.",
//     imageUrl: ProjectImages?.BudgetBiteLogin,
//     languages: {
//       Python: 60,
//       Javascript: 20,
//       HTML: 8,
//       CSS: 12
//     },
//     info: "Description info for BudgetBite Login Page",
//     sourceCode: "Link to source code for BudgetBite Login Page",
//     pricing: 680,
//     photos: {
//       // Photos information for BudgetBite Login Page
//     }
//   },
//   {
//     id: 3,
//     title: "Edas Careers Network Website",
//     type: "Website",
//     description: "Laptop on scroll view of Edas Careers Network Website.",
//     imageUrl: ProjectImages?.EdasCareersNetworkWebsite,
//     languages: {
//       Python: 30,
//       HTML: 60,
//       CSS: 10
//     },
//     info: "Description info for Edas Careers Network Website",
//     sourceCode: "Link to source code for Edas Careers Network Website",
//     pricing: 680,
//     photos: {
//       // Photos information for Edas Careers Network Website
//     }
//   },

//   {
//     id: 2,
//     title: "ARMS User Dashboard",
//     type: "Web Application",
//     description: "Laptop view of Automated Records Management System User Dashboard Page.",
//     imageUrl: ProjectImages?.ArmsDashboardPage,
//     languages: {
//       Python: 50,
//       Javascript: 30,
//       HTML: 5,
//       CSS: 15
//     },
//     info: "Description info for ARMS User Dashboard",
//     sourceCode: "Link to source code for ARMS User Dashboard",
//     pricing: 750,
//     photos: {
//       // Photos information for ARMS User Dashboard
//     }
//   }

//  ,
//   {
//     id: 1,
//     title: "ARMS Signup Page",
//     type: "Web Application",
//     description: "Laptop view of Automated Records Management System Signup Page.",
//     imageUrl: ProjectImages?.ArmsSignupPage,
//     languages: {
//       Javascript: 85,
//       HTML: 5,
//       CSS: 10
//     },
//     info: "Description info for ARMS Signup Page",
//     sourceCode: "Link to source code for ARMS Signup Page",
//     pricing: 720,
//     photos: {
//       // Photos information for ARMS Signup Page
//     }
//   },

//   {
//     id: 0,
//     title: "ARMS Login Page",
//     type: "Web Application",
//     description: "Laptop view of Automated Records Management System Login Page.",
//     imageUrl: ProjectImages?.ArmsLoginPage,
//     languages: {
//       Python: 30,
//       HTML: 60,
//       CSS: 10
//     },
//     info: "Description info for ARMS Login Page",
//     sourceCode: "Link to source code for ARMS Login Page",
//     pricing: 680,
//     photos: {
//       // Photos information for ARMS Login Page
//     }
//   }
  
];

export default ProjectData;



  