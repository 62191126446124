import React from "react";
import { NavLink, Link, Outlet, useSearchParams, useLocation } from "react-router-dom";



export default function ProjectLayout() {

     const [searchParams, setSearchParams] = useSearchParams();
     const typeParam = searchParams.get("type");

     // Link - Merge query parameters into links
     function genNewSearchParamString(key, value) {
          const sp = new URLSearchParams(searchParams)
          if (value === null) {
               sp.delete(key)
          } else {
               sp.set(key, value)
          }
          return `?${sp.toString()}`
     }

     const location = useLocation();
   
     // Extract the searchParams and type from the state
     const { type: currentFilterPath } = location.state || {};
    
     return (
          <div className="container-fluid px-0 py-5 d-flex flex-column min-vh-100 align-items-center bgc-about-custom" >
               <div className="container">
                    <nav className="container navbar navbar-dark fixed-top my-5 pt-5 pb-2 px-5 bg-black">

                         <NavLink 
                              to={`.${genNewSearchParamString("type", null)}`}  
                              relative="path"
                              end
                              className={(!currentFilterPath && !typeParam) ? "active-link" : "fc4"}
                         >
                              Dashboard
                         </NavLink>

                         <NavLink 
                              to={`ai${genNewSearchParamString("type", "AI")}`}
                              className={({isActive}) => (isActive || currentFilterPath === "AI" || typeParam === "AI") ? "active-link" : "fc4"}
                              style={{ marginLeft: '1.875rem' }} 
                         >
                              AI
                         </NavLink>

                         <NavLink 
                              to={`websites${genNewSearchParamString("type", "Website")}`} 
                              className={({isActive}) => (isActive || currentFilterPath === "Website" || typeParam === "Website") ? "active-link" : "fc4"}
                              style={{ marginLeft: '1.875rem' }} 
                         >
                              Websites
                         </NavLink>

                         <NavLink 
                              to={`webapps${genNewSearchParamString("type", "Web Application")}`}  
                              className={({isActive}) => (isActive || currentFilterPath === "Web Application" || typeParam === "Web Application") ? "active-link" : "fc4"}
                              style={{ marginLeft: '1.875rem' }} 
                         >
                              WebApps
                         </NavLink>

                         <NavLink 
                              to={`data-analysis${genNewSearchParamString("type", "Data Analysis")}`}  
                              className={({isActive}) => (isActive || currentFilterPath === "Data Analysis" || typeParam === "Data Analysis") ? "active-link" : "fc4"}
                              style={{ marginLeft: '1.875rem' }} 
                         >
                              Data Analysis
                         </NavLink>

                    </nav>
                    <Outlet />
               </div>
          </div>
     )
}



 // Buttons - Merge query parameters into links
     // function handleFilterChange(key, value) {
     //      setSearchParams(prevParams => {
     //           if (value === null) {
     //                prevParams.delete(key)
     //           } else {
     //                prevParams.set(key, value)
     //           }
     //           return prevParams
     //      })
     // }