import React from 'react';
import { motion } from "framer-motion";
import { fadeIn } from '../../variants';
import "./services.css";
import { Link } from 'react-router-dom';

export default function Services({ includesacMoreButtons }) {

  // sacMoreButtons for AIConsulting, FullStackDevelopment, and BigDataAnalytics
  const sacMoreButtons = includesacMoreButtons
    ? <>
      <Link to="/services" className="btn border-0 mx-3 btn-outline-primary">Learn More</Link>
    </>
    : null;

  return (
    <div className="container-fluid px-0 py-5 min-vh-100 d-flex justify-content-center align-items-center bg-services">
      <div className="container py-5">
        <motion.div
          variants={fadeIn("down", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.6 }}
        >
          <h3 className='fc3 fw-bold my-4'>My <span className='fc4'>Services</span></h3>
        </motion.div>
        <div className="res__divider sst"></div>
        <div className="row align-items-stretch">
          <AIConsulting sacMoreButtons={sacMoreButtons} />
          <FullStackDevelopment sacMoreButtons={sacMoreButtons} />
          <BigDataAnalytics sacMoreButtons={sacMoreButtons} />
        </div>
      </div>
    </div>
  );
}

const AIConsulting = ({ sacMoreButtons }) => {
  return (
    <div className="col-lg-4 col-md-6 mb-4 d-flex">
      <motion.div
        className="card w-100"
        variants={{
          hidden: { scale: 0.8 },
          show: { scale: 1, transition: { ease: "easeOut", duration: 1.5 } }
        }}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.9 }}
      >
        <div className="card-body">
          <i className="fas fa-robot fw-bold fs-2 text-success"></i>
          <h2 className="card-title fs-4 my-1">AI Strategies and Consulting</h2>
          <p className="card-text">Providing strategic guidance and consulting services to help organizations harness the power of artificial intelligence. I evaluate your needs and identify opportunities for AI adoption, ensuring alignment with your business objectives.</p>
        </div>
        {sacMoreButtons && <p className="fc4">{sacMoreButtons}</p>}
      </motion.div>
    </div>
  );
};

const FullStackDevelopment = ({ sacMoreButtons }) => {
  return (
    <div className="col-lg-4 col-md-6 mb-4 d-flex">
      <motion.div
        className="card w-100"
        variants={{
          hidden: { scale: 0.8 },
          show: { scale: 1, transition: { ease: "easeOut", duration: 1.5 } }
        }}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.9 }}
      >
        <div className="card-body">
          <i className="fas fa-code fw-bold fs-2 text-primary"></i>
          <h2 className="card-title fs-4 my-1">Full-Stack Development</h2>
          <p className="card-text">Expertise in both front-end and back-end development, delivering end-to-end solutions. I create user-friendly interfaces and robust server-side logic, ensuring your applications are fully functional.</p>
        </div>
        {sacMoreButtons && <p className="fc4">{sacMoreButtons}</p>}
      </motion.div>
    </div>
  );
};

const BigDataAnalytics = ({ sacMoreButtons }) => {
  return (
    <div className="col-lg-4 col-md-6 mb-4 d-flex">
      <motion.div
        className="card w-100"
        variants={{
          hidden: { scale: 0.8 },
          show: { scale: 1, transition: { ease: "easeOut", duration: 1.5 } }
        }}
        initial="hidden"
        whileInView={"show"}
        viewport={{ once: false, amount: 0.9 }}
      >
        <div className="card-body">
          <i className="fas fa-database fw-bold fs-2 text-success"></i>
          <h2 className="card-title fs-4 my-1">Big Data Analytics</h2>
          <p className="card-text">Proficiency in handling and analyzing large datasets using tools like Python and PowerBI. I uncover valuable insights from your data, enabling data-driven decisions that drive your business forward.</p>
        </div>
        {sacMoreButtons && <p className="fc4">{sacMoreButtons}</p>}
      </motion.div>
    </div>
  );
};


