import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const containerStyle = {
    zIndex: "9999",
};

const ErrorPage = () => (
    <section className="container-fluid m-0 p-0 d-flex justify-content-center align-items-center bg-services" style={{ height: "calc(100vh - 3.3rem)", overflow: "hidden" }}>
        <div className="container mx-auto text-white fw-bold d-flex flex-column justify-content-center align-items-center" style={containerStyle}>
            <h1 className="fw-bold fs-2">Error - 404</h1>
            <p className="fw-bold text-center">Sorry, the page you were looking for was not found.</p>
            <Link to='/' className="btn border-0 btn-outline-primary fw-bold">
                <FontAwesomeIcon icon={faArrowLeft} /> Return to Home
            </Link>
        </div>
    </section>
);

export default ErrorPage;


