import React from 'react';

export default function Skills() {
  return (
    <div className="container-fluid px-0 py-5 min-vh-100 d-flex justify-content-center align-items-center bgc-about-custom">
      <div className="container py-5">
        <div className="alert alert-warning" role="alert">
          Warning: This view is likely being updated on the server. It will display as soon as it is ready, you can continue with other views.
        </div>
      </div>
    </div>
  );
}


