import React from 'react';
import { NavLink, Link, Outlet, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import ProjectData from './ProjectData';


// ProjectImage component
const ProjectImage = ({ project }) => {
  return (
    <div className="col-md-8">
      <img src={project.imageUrl} alt={project.title} className="img-fluid rounded" />
    </div>
  );
};

// ProjectDetails component
const ProjectDetails = ({ project, totalPercentage }) => {

  // Define an array of unique colors
  const colors = ['#007bff', '#ffc107', '#28a745', '#dc3545'];

  return (
    <div className="col-md-4 text-dark">
      <p className="fw-bold my-2">{project.type}</p>
      <h2 className="fw-bold">{project.title}</h2>
      <p className="fs-4">Languages:</p>
      <div className="progress mb-3">
        {Object.entries(project.languages).map(([language, percentage], index) => (
          <div
            key={language}
            className="progress-bar"
            role="progressbar"
            style={{
              width: `${(percentage / totalPercentage) * 100}%`,
              backgroundColor: colors[index % colors.length] // Use modulo to loop through colors array
            }}
            aria-valuenow={percentage}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        ))}
      </div>
      <div className="d-flex flex-column">
        {Object.entries(project.languages).map(([language, percentage], index) => (
          <span key={language} className="mb-2">
            <span className="dot fs-6" style={{ backgroundColor: colors[index % colors.length] }}></span> {language}: {percentage}%
          </span>
        ))}
      </div>
    </div>
  );
};


function ProjectDetailPageNavigation() {
  const location = useLocation();
  const { search, state } = location;

  // Extract the searchParams and type from the state
  const { search: searchParams, type } = state || {};

  return (
    <nav className="container-fluid navbar navbar-dark mx-auto my-3 p-3 bg-secondary">
      <NavLink to="." end className={({ isActive }) => (isActive ? "active-link" : "fc4")} state={{ search: searchParams, type }}>
        Detailed Info
      </NavLink>
      <NavLink to="photos" className={({ isActive }) => (isActive ? "active-link" : "fc4")} state={{ search: searchParams, type }} style={{ marginLeft: '1.875rem' }}>
        Photos
      </NavLink>
      <NavLink to="source-code" className={({ isActive }) => (isActive ? "active-link" : "fc4")} state={{ search: searchParams, type }} style={{ marginLeft: '1.875rem' }}>
        Source Code
      </NavLink>
      <NavLink to="pricing" className={({ isActive }) => (isActive ? "active-link" : "fc4")} state={{ search: searchParams, type }} style={{ marginLeft: '1.875rem' }}>
        Pricing
      </NavLink>
    </nav>
  );
}

const ProjectDetailsPage = () => {
  const { id } = useParams(); // Extract the ID parameter from the URL

  const location = useLocation();

  const [currentProject, setCurrentProject] = React.useState(null); // useOutletContext

  // Find the project with the matching ID
  const project = ProjectData.find((project) => project.id.toString() === id);

  // If no project found with the given ID, display a message
  if (!project) {
    return (
      <div className="my-5">
        <div className="container p-5 fw-bold text-white">
          <h2 className="fs-5">Project not found!</h2>
        </div>
      </div>
    );
  }

  // Calculate total percentage
  const totalPercentage = Object.values(project.languages).reduce((acc, cur) => acc + cur, 0);

  const search = location.state?.search || ""; // Optional chaining, in case of a null state
  const type = location.state?.type || "all";

  // Display the details of the selected project
  return (
    <div className="container-fluid my-5">
      <div className="container py-5">
        <Link to={`..${search}`} relative="path" className="btn mb-3 border-0 btn-outline-primary">
          <FontAwesomeIcon icon={faArrowLeft} /> Back to {type} projects
        </Link>
        <div className="row mx-3 bg-white p-lg-4">
          <ProjectImage project={project} />
          <ProjectDetails project={project} totalPercentage={totalPercentage} />
          <ProjectDetailPageNavigation />
          <Outlet context={{ currentProject: project }} />
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsPage;









// // Display the details of the selected project
// return (
//     <div className='container-fluid my-5'> 
//         <div className="container p-5 ">
//             {/* <h2 className="mb-4">{project.title}</h2> */}
//             <div className="row p-5 bg-white">
//                 <div className="col-md-8">
//                     <img src={project.imageUrl} alt={project.title} className="img-fluid rounded" />
//                 </div>
//                 <div className="col-md-4 text-dark">
//                     <p className="fw-bold my-2">{project.type}</p>
//                     <h2 className="fw-bold">{project.title}</h2>
//                     <p>Languages:</p>
//                     <ul className="list-unstyled">
//                         {Object.entries(project.languages).map(([language, percentage]) => (
//                             <li key={language} className={`fw-bold mb-2 ${language === 'Python' ? 'text-primary' : 'text-warning'}`}>
//                                 {language}: {percentage}%
//                                 <div className="progress">
//                                     <div
//                                         className={`progress-bar ${language === 'Python' ? 'bg-primary' : 'bg-warning'}`}
//                                         role="progressbar"
//                                         style={{ width: `${percentage}%` }}
//                                         aria-valuenow={percentage}
//                                         aria-valuemin="0"
//                                         aria-valuemax="100"
//                                     ></div>
//                                 </div>
//                             </li>
//                         ))}
//                     </ul>
//                 </div>
//             </div>
//         </div>
//     </div>
// );
