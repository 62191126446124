import React from 'react';
import { motion } from "framer-motion";
import Swal from 'sweetalert2';
import "./contact.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt, faClock } from '@fortawesome/free-solid-svg-icons';


export default function Contact() {
  return (
      <div className="container-fluid bg-light px-0">

          <div className="container-fluid px-0 bg-contact">
            <div className="color-overlay d-flex justify-content-center align-items-center">
              <ContactHeader />
            </div>
          </div>

          <div className="container py-5">
            <div className="row mx-auto raised-top">
              <ContactInfo />
              <ContactForm />
            </div>
          </div>

      </div>
  );
}

function ContactHeader() {
  return (
      <div className="container">
        <div className="row">
            <motion.div 
              className="col-md-12 text-center"
              variants={{
                hidden: { scale: 0.8 },
                show: { scale: 1, transition: { ease: "easeOut", duration: 1.1 } }
              }}
              initial="hidden"
              // animate="show"
              whileInView={"show"}
              viewport={{ once: false, amount: 0.7 }}
            >
              {/* <h2 className="fs-6 fw-bold">CONTACT ME</h2> */}
              <h1 className="fs-1 fw-bold">Get In Touch With Me</h1>
              <p className="lead">I would love to hear from you, and discuss how we can make your vision a reality.</p>
            </motion.div>
        </div>
      </div>
  );
}


function ContactInfo() {
  return (
      <div className="col-md-6 px-0 custom-mb bg-issa">
          <div className="color-overlay2 p-4">
            <h6 className='mb-2'>CONTACT ME</h6>
            <h3 className='mb-4'>My Contact Information</h3>
            <p className="mb-2"><FontAwesomeIcon icon={faPhone} /> +254 759 599 492</p>
            <p className="mb-2"><FontAwesomeIcon icon={faEnvelope} /> issakatana855@gmail.com</p>
            <p className="mb-2"><FontAwesomeIcon icon={faMapMarkerAlt} /> P.O BOX, 1213-80201, Kilifi</p>
            <p className="mb-2"><FontAwesomeIcon icon={faClock} /> Mon-Fri, 8am - 5pm</p>
          </div>
      </div>
  );
}


function ContactForm() {

  const handleSubmit = async (e) => {
    e.preventDefault();

    const scriptURL = 'https://script.google.com/macros/s/AKfycbxescs0LbJXLkdbpZo1Jh17AV4H97oCOsf-UAqE8Yi2gQa4nMqWvPjau8SNLz3QugbbAA/exec';
    const form = e.target;

    try {
      // Show preloader
      Swal.fire({
          title: 'Submitting...',
          html: 'Please wait while we process your request.',
          icon: 'info',
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
              Swal.showLoading();
          }
      });
  
      const response = await fetch(scriptURL, {
          method: 'POST',
          body: new FormData(form)
      });
  
      if (response.ok) {
          Swal.fire({
              title: 'Success!',
              text: 'Your form has been submitted successfully.',
              icon: 'success',
              confirmButtonText: 'Okay'
          }).then(() => {
              form.reset(); // Clear form input fields
          });
      } else {
          Swal.fire({
              title: 'Oops!',
              text: 'Failed to submit the form. Please try again later.',
              icon: 'error',
              confirmButtonText: 'Okay'
          });
      }
  } catch (error) {
      Swal.fire({
          title: 'Error!',
          text: 'An unexpected error occurred. Please try again later.',
          icon: 'error',
          confirmButtonText: 'Okay'
      });
  } finally {
      // Hide preloader
      // Swal.close();
  }
  
  };

  return (
    <div className="col-md-6">
      <form name="submit-to-google-sheet" onSubmit={handleSubmit}>

        <div className="mb-3">
          <label htmlFor="name" className="form-label fw-bold">Name</label>
          <input 
            type="text"
            className="form-control bg-light" 
            id="name" name="name"
            placeholder="Enter your name or company name" 
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="form-label fw-bold">Email</label>
          <input 
            type="email" 
            className="form-control bg-light" 
            id="email" 
            name="email" 
            placeholder="Enter your email" 
            required
          />
        </div>
        
        <div className="mb-3">
          <label htmlFor="phone" className="form-label fw-bold">Phone Number</label>
          <input 
            type="tel" 
            className="form-control bg-light" 
            id="phone" 
            name="phone" 
            placeholder="Enter your phone number" 
            required
          />
        </div>
        
        <div className="mb-3">
          <label htmlFor="message" className="form-label fw-bold">Message</label>
          <textarea 
            className="form-control bg-light" 
            id="message" 
            name="message" 
            rows="5" 
            placeholder="Enter your message" 
            required
          ></textarea>
        </div>

        <button type="submit" className="btn btn-primary px-5 fw-bold">Submit</button>
      </form>
    </div>
  );
}




