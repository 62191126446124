import { useParams, useOutletContext} from 'react-router-dom';


const ProjectInfo = () => {
    const { id, section } = useParams(); 
    const { currentProject } = useOutletContext();

    // Check if currentProject exists and has the correct ID
    if (!currentProject || currentProject.id !== parseInt(id)) {
        return <div>Project not found</div>;
    }

    // Find the project data based on the ID
    // const project = ProjectData.find(project => project.id === parseInt(id));

    // if (!project) {
    //     return <div>Project not found</div>;
    // }

    // Render the corresponding information based on the section
    let content;
    switch (section) {
        case undefined: // Index route
            content = (
                <div>
                    <div>{currentProject.info}</div>
                </div>
            );
            break;
        case 'info':
            if (!currentProject.info) {
                content = <div>No information available for this project</div>;
            } else {
                content = <div>{currentProject.info}</div>;
            }
            break;
        case 'source-code':
            if (!currentProject.sourceCode || Object.keys(currentProject.sourceCode).length === 0) {
                content = <div>No source code available for this project</div>;
            } else {
                content = <div>{currentProject.sourceCode}</div>;
            }
            break;
        case 'pricing':
            if (!currentProject.pricing || isNaN(currentProject.pricing)) {
                content = <div>No pricing information available for this project</div>;
            } else {
                content = <div>$ {currentProject.pricing}</div>;
            }
            break;
        case 'photos':
            if (Object.keys(currentProject.photos).length === 0) {
                content = <div>No photos available for this project</div>;
            } else {
                // Render photos here based on your data structure
                // For example, you can map over the photos and render them as <img> elements
                content = (
                    <div>
                        {Object.keys(currentProject.photos).map(photo => (
                            <img key={photo} src={currentProject.photos[photo]} alt={photo} />
                        ))}
                    </div>
                );
            }
            break;
        default:
            content = <div>Invalid project section</div>;
    }

    return (
        <div>
            {content}
        </div>
    );
};

export default ProjectInfo;
