import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import Layout from './components/base/layout/Layout';
import ProjectLayout from './components/base/layout/ProjectsLayout';

import BriefSkills from './components/main/about/aboutextentions/BriefSkills';
import Education from './components/main/about/aboutextentions/Education';
import Experience from './components/main/about/aboutextentions/Experience';

import ProjectDetailsPage from './components/main/projects/ProjectDetailsPage';
import ProjectInfo from './components/main/projects/projectdetails/Info';


import Home from './components/main/home/Home';
import About from './components/main/about/About';
import Skills from './components/main/skills/Skills';
import Services from './components/main/services/Services';
import Projects, { loader as projectsLoader } from './components/main/projects/Projects';
import Contact from './components/main/contact/Contact';
import ErrorPage from './components/base/errorPage/ErrorPage';

import AI from './components/main/projects/whatido/AI';
import WebApps from './components/main/projects/whatido/WebApps';
import Websites from './components/main/projects/whatido/Websites';
import DataAnalysis from './components/main/projects/whatido/DataAnalysis';

import ErrorElement from './components/base/errorPage/ErrorElement';

const router = createBrowserRouter(createRoutesFromElements(
  <Route element={<Layout />}>
    <Route path="*" element={<ErrorPage />} />
    <Route path="/" element={<Home includeAdditionalContent={true} />}>
      <Route index element={<BriefSkills />} />
      <Route path="experience" element={<Experience />} /> 
      <Route path="education" element={<Education />} />
    </Route>
    <Route path="about" element={<About includeAdditionalContent={true} />} />
    <Route path="skills" element={<Skills />} />
    <Route path="services" element={<Services />} />
    <Route path="projects" element={<ProjectLayout />}  >
      <Route index element={<Projects />} loader={projectsLoader} errorElement={<ErrorElement />} />
      <Route path=":id" element={<ProjectDetailsPage />}>
        <Route index element={<ProjectInfo />} />
        <Route path=":section" element={<ProjectInfo />} />
      </Route>
      <Route path="ai" element={<AI />} />
      <Route path="websites" element={<Websites />} />
      <Route path="webapps" element={<WebApps />} />
      <Route path="data-analysis" element={<DataAnalysis />} />
    </Route>
    <Route path="contact" element={<Contact />} />
  </Route>
))


const App = () => (
  <RouterProvider router={router} />
);

export default App;






{/*
import { BrowserRouter, RouterProvider, createBrowserRouter, createRoutesFromElements, Routes, Route } from 'react-router-dom';
<BrowserRouter>
<Routes>
  <Route element={<Layout />}>

    <Route path="*" element={<ErrorPage />} />

    <Route path="/" element={<Home includeAdditionalContent={true} />}>
      <Route index element={<BriefSkills />} />
      <Route path="experience" element={<Experience />} /> 
      <Route path="education" element={<Education />} />
    </Route>

    <Route path="about" element={<About includeAdditionalContent={true} />} />
    <Route path="skills" element={<Skills />} />
    <Route path="services" element={<Services />} />

    <Route path="projects" element={<ProjectLayout />}>
      <Route index element={<Projects />} />

      <Route path=":id" element={<ProjectDetailsPage />}>
        <Route index element={<ProjectInfo />} />
        <Route path=":section" element={<ProjectInfo />} />
      </Route>

      <Route path="ai" element={<AI />} />
      <Route path="websites" element={<Websites />} />
      <Route path="webapps" element={<WebApps />} />
      <Route path="data-analysis" element={<DataAnalysis />} />
    </Route>

    <Route path="contact" element={<Contact />} />

  </Route>
</Routes>
</BrowserRouter> */}