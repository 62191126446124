import React, { useEffect, useState } from 'react';
import ProjectData from '../ProjectData';
import { Link, useSearchParams, useLocation, useLoaderData } from 'react-router-dom';
import { motion } from 'framer-motion';
import { fadeIn } from '../../../variants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default function LandingProjects() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const typeParam = searchParams.get("type");

  // Filter ProjectData based on the type parameter
  const filteredProjects = typeParam ? ProjectData.filter(project => project.type === typeParam) : ProjectData.slice(0, 3); // Slice to get only the first 3 projects

  // Check if there are no projects
  const noProjects = filteredProjects.length <= 0;

  return (
    <div className='pt-2 mx-auto'>
      <div className="container d-flex justify-content-center align-items-center flex-column" style={{ minHeight: '90vh' }}>
        <motion.div
          variants={fadeIn("down", 0.3)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: false, amount: 0.6 }}
        >
          <h3 className='fc3 fw-bold my-4'>My Latest Projects<span className='fc4'></span></h3>
        </motion.div>
        <div className="row">
          {noProjects ? (
            <h6 className="alert alert-warning" role="alert">No projects found or Projects are not yet updated yet.</h6>
          ) : (
            filteredProjects.map((project, index) => (
              <ProjectCard key={index} project={project} index={index} searchParams={searchParams} typeParam={typeParam} location={location} />
            ))
          )}
        </div>
      </div>
    </div>
  );
}


function ProjectCard({ project, index, searchParams, typeParam, location }) {
  const { id, title, type, description, imageUrl } = project;

  const cardVariants = [
    {
      className: "col-lg-4 col-md-6 mb-4 px-2 border-0",
      variants: {
        hidden: { x: -100, opacity: 0 },
        show: { x: 0, opacity: 1, transition: { ease: "easeOut", duration: 0.5, delay: index * 0.2 } }
      }
    },
    {
      className: "col-lg-4 col-md-6 mb-4 px-2 border-0",
      variants: {
        hidden: { scale: 0.8 },
        show: { scale: 1, transition: { ease: "easeOut", duration: 0.9 } }
      }
    },
    {
      className: "col-lg-4 col-md-6 mb-4 px-2 border-0",
      variants: {
        hidden: { x: 100, opacity: 0 },
        show: { x: 0, opacity: 1, transition: { ease: "easeOut", duration: 0.5, delay: index * 0.2 } }
      }
    }
  ];

  return (
    <motion.div 
      className={`card h-100 ${cardVariants[index].className}`} 
      variants={cardVariants[index].variants} 
      initial="hidden"
      whileInView={"show"}
      viewport={{ once: false, amount: 0.6 }}
    >
      <img className="card-img-top" src={imageUrl} alt={title} />
      <div className="card-body">
        <h5 className="card-title fs-6 fw-bold">{title}</h5>
        <p className="card-text">{description}</p>
      </div>
      <div className="card-footer d-flex justify-content-between">
        <small className="text-muted">{type}</small>
        <Link
          to={`/projects/${id}`}
          state={{
            search: `?${searchParams.toString()}`,
            type: typeParam // Pass the typeParam here
          }}
          className="btn btn-sm border-0 btn-outline-secondary"
        >
          View Details
          <FontAwesomeIcon icon={faArrowRight} />
        </Link>
      </div>
    </motion.div>
  );
}





