import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';


export default function ProjectCard({ project, index, searchParams, typeParam }) {
    const { id, title, type, description, imageUrl } = project;
  
    const cardVariants = {
        hidden: { scale: 0.8 },
        show: { scale: 1, transition: { ease: "easeOut", duration: 0.9 + index * 0.3 } }
    };
  
    return (
        <motion.div 
            className="col-lg-4 col-md-6 mb-4"
            variants={cardVariants} 
            initial="hidden"
            whileInView={"show"} 
            viewport={{ once: false, amount: 0.9 }}
        >
            <div className="card h-100">
                <img className="card-img-top" src={imageUrl} alt={title} />
                <div className="card-body">
                    <h5 className="card-title fs-6 fw-bold">{title}</h5>
                    <p className="card-text">{description}</p>
                </div>
                <div className="card-footer d-flex justify-content-between">
                    <small className="text-muted">{type}</small>
                    <Link
                        to={`/projects/${id}`}
                        state={{
                            search: `?${searchParams.toString()}`,
                            type: typeParam 
                        }}
                        className="btn btn-sm border-0 btn-outline-secondary"
                    >
                    View Details
                    <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                </div>
            </div>
        </motion.div>
    );
  }

